body {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Calibri;
  font-size: 16px;
  font-weight: normal;

}

html, #root {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  font-family: Poppins !important;
  overflow: hidden;

  * {
    font-family: Poppins !important;
    scrollbar-color: #dee2e7 #fff;
    scrollbar-width: thin !important;

    ::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border: 4.5px solid transparent;
      border-left-width: 2px;
      border-right-width: 2px;
      border-radius: 2px;
      background-clip: content-box;
    }

    ::-webkit-scrollbar {
      background-color: #fff;
      width: 11px;
      overflow: visible;
      height: 15px;
    }
  }


  .uf_loading {
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 17px;
  }

}
